import React from 'react'
import Four from "../assets/Group1.png"
import '../index.css'
import Button from './Button'

const Herozam = () => {
  return (
    <div className='bg-[#F8F8F8] pb-20'>
        <div className='pt-11 pb-20 flex flex-row '>
            <div className='px-5 md:mx-11 md:w-3/5'>
                <h1 className='text-3xl md:text-5xl j-font4'>Nabízíme komplexní zámečnické služby s důrazem na kvalitu a rychlost</h1>
                <p className='text-xl md:text-2xl pt-5 j-font5'>Naši zkušení zámečníci z Prahy poskytují prvotřídní zámečnické služby dostupné kdykoliv potřebujete. S důrazem na rychlost a kvalitu vám garantujeme bezpečí a spolehlivost. Naše služby jsou dostupné 24/7.</p>
                <div className='flex pt-10 items-center'>
                    <Button />
                </div>
            </div>
            <div className='md:flex md:px-11 md:justify-center md:items-center hidden md:w-3/5'>
                <img src={Four} alt='' className='w-full h-auto' />
            </div>
        </div>
        <div>
        </div>
    </div>
  )
}

export default Herozam
