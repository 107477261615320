import { useState } from "react";

import  close from "../assets/close.png";
import  menu from "../assets/menu.png";
import  logot from "../assets/logo-text.png";
import logom from "../assets/logo-text.png"

import { navLinks } from "../constants";
import { HashLink as Link } from "react-router-hash-link";

const Navbar = () => {
  const [active, setActive] = useState("Home");
  const [toggle, setToggle] = useState(false);

  return (
    <nav className="w-full bg-[#F8F8F8] flex justify-between items-center navbar px-10">
      

      <img
        src={logot}
        alt="Large Logo"
        className="hidden sm:hidden md:block lg:block xl:block 2xl:block w-[500px] h-[115px]"
      />
      {/* Small screen logo */}
      <img
        src={logom}
        alt="Small Logo"
        className="block sm:block md:hidden lg:hidden xl:hidden 2xl:hidden w-[260px] h-[70px]"
      />

      <ul className="list-none sm:flex hidden justify-end items-center flex-1">
        {navLinks.map((nav, index) => (
          <li
            key={nav.id}
            className={`j-font5 hover:text-blue-600 font-normal cursor-pointer md:text-[22px] text-[16px] ${
              active === nav.title ? "text-white" : "text-black"
            } ${index === navLinks.length - 1 ? "mr-10" : "mr-20"}`}
            onClick={() => setActive(nav.title)}
          >
            <Link to={`#${nav.id}`} smooth>{nav.title}</Link>
          </li>
        ))}
      </ul>

      <div className="sm:hidden flex flex-1 justify-end items-center">
        <img
          src={toggle ? close : menu}
          alt="menu"
          className="w-[28px] h-[28px] object-contain"
          onClick={() => setToggle(!toggle)}
        />

        <div
          className={`${
            !toggle ? "hidden" : "flex"
          } p-6 bg-white-gradient bg-slate-500 absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}
        >
          <ul className="list-none flex justify-end items-start flex-1 flex-col">
            {navLinks.map((nav, index) => (
              <li
                key={nav.id}
                className={`j-font3  font-medium cursor-pointer text-[24px] ${
                  active === nav.title ? "text-black" : "text-white"
                } ${index === navLinks.length - 1 ? "mb-0" : "mb-4"}`}
                onClick={() => setActive(nav.title)}
              >
                <a href={`#${nav.id}`}>{nav.title}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;