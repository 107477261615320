import React from 'react';
import call from '../assets/call_orange.png'; // Import your logo image
import '../index.css';

const Button = () => {
  return (
    <div>
        <a href="tel:+420 608 097 250">
        <button className='flex justify-center items-center button-zam'>
           <img src={ call } />
           <p>608 097 250</p>
        </button>
        </a>
    </div>
  )
}

export default Button