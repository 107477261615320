import React, { useState } from 'react';
import image1 from '../assets/Group 1.png';
import image2 from '../assets/Group 2.png';
import image3 from '../assets/Group 3.png';
import image4 from '../assets/Group 4.png';
import image5 from '../assets/Group 5.png';
import image6 from '../assets/Group 6.png';
import image7 from '../assets/Group 7.png';
import image8 from '../assets/Group 8.png';
import "../index.css";

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8
];

const Carousell = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="bg-[#F8F8F8] relative w-full max-w-5xl mx-auto pb-20 px-4">
      <p className='j-font4 text-4xl md:text-6xl text-gray-900 text-center pb-11'>Služby</p>
      <div className="overflow-hidden">
        <div
          className="flex transition-transform duration-500"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((src, index) => (
            <div key={index} className="flex-none w-full md:w-1/3">
              <img
                src={src}
                alt={`Slide ${index}`}
                className="w-full h-auto object-cover"
              />
            </div>
          ))}
        </div>
      </div>
      <button
        className="absolute top-1/2 left-4 md:left-0 transform -translate-y-1/2 bg-gray-600 text-white p-2 md:p-4"
        onClick={prevImage}
      >
        Zpět
      </button>
      <button
        className="absolute top-1/2 right-4 md:right-0 transform -translate-y-1/2 bg-gray-600 text-white p-2 md:p-4"
        onClick={nextImage}
      >
        Další
      </button>
    </div>
  );
}

export default Carousell;
